const keysDescription = {
	'4d.nbutilisateurs': {label: "4D - Nombre de postes de la licence"},
	'4d.platform': {label: "4D - Système d'exploitation"},
	'4d.version': {label: "4D - Version"},
	'cloud.actif': {label: "Cloud - Actif", editable: true, type: "boolean"},
	'cloud.agence.nom': {label: "Cloud - Nom de l'agence", editable: true, type: "string", mandatoryAtCreation: true},
	'cloud.agence.trigramme': {label: "Cloud - Trigramme de l'agence", editable: true, type: "string", mandatoryAtCreation: true},
	'cloud.agence.visible': {label: "Cloud - Visible des autres agences", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'true'},
	'cloud.autorisations.lecture.global.agences': {label: "Cloud - Agences autorisées en lecture", editable: true, type: "string"},
	'cloud.manageapi.key': {label: "Cloud - Manage Model API - Clé d'accès", editable: true, type: "string"},
	'cloud.manageapi.url': {label: "Cloud - Manage Model API - URL", editable: true, type: "string"},
	'cloud.tempsattenteentresynchro': {label: "Cloud - Temps d'attente entre les synchronisations", editable: true, type: "number", defaultAtCreation: '300'},
	'cloud.token': {label: "Cloud - Token (uniquement pour le cloud 4D)", editable: true, type: "string"},
	'cloud.updown.id': {label: "Cloud - Identifiant UpDown.io", editable: true, type: "string"},
	'cloud.versionmpd': {label: "Cloud - Version du modèle de données", editable: true, type: "string"},
	'cloud.wakanda.login': {label: "Cloud - Wakanda - Login", editable: true, type: "string"},
	'cloud.wakanda.password': {label: "Cloud - Wakanda - Mot de passe", editable: true, type: "string"},
	'cloud.wakanda.url': {label: "Cloud - Wakanda - URL", editable: true, type: "string"},
	'ea.actif': {label: "Early Access - Actif", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'ea.coefremise': {label: "Early Access - Coefficient de remise", editable: true, type: "number", min: 0, max: 100},
	'ea.datedebut': {label: "Early Access - Date de début", editable: true, type: "date"},
	'ea.datefin': {label: "Early Access - Date de fin", editable: true, type: "date"},
	'general.siret': {label: "Général - SIRET de l'entreprise", editable: true, type: "string", regex: /(1-9){14}/g, mandatoryAtCreation: true},
	'general.numerotvaintracommunautaire': {label: "Général - Numéro de TVA intracommunautaire de la société", editable: true, type: "string", mandatoryAtCreation: true},
	'general.devisepivot.base': {label: "Général - Base de la devise pivot", editable: true, type: "enum", values:['1', '10', '100', '1000'], mandatoryAtCreation: true, defaultAtCreation: '1'},
	'general.devisepivot.coupuremini': {label: "Général - Coupure mini. de la devise pivot", editable: true, type: "number", mandatoryAtCreation: true, defaultAtCreation: '0.01'},
	'general.devisepivot.iso': {label: "Général - Code ISO de la devise pivot", editable: true, type: "enum", values: ['EUR', 'MAD', 'CAD', 'XPF', 'SCR'] , mandatoryAtCreation: true, defaultAtCreation: 'EUR'},
	'general.emailowner': {label: "Général - Email du gestionnaire d'agence", editable: true, type: "email", mandatoryAtCreation: true},
	'general.paysdactivite': {label: "Général - Pays d'activité de l'agence", editable: true, type:"enum", values:['FR', 'MC', 'MA', 'QC', 'ES', 'TOM', 'BE', 'NL', 'SC', 'LU'], mandatoryAtCreation: true, defaultAtCreation: 'FR'},
	'general.vigilance.seuilmetaux': {label: "Général - Seuil réglementaire en vigueur pour le pays d'activité concernant la vigilance renforcée pour les métaux", editable: true, type: "number"},
	'licence.assistance.actif': {label: "Licence - Droit au support", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'true'},
	'licence.nbguichetsadmin': {label: "Licence - Nombre de guichets \"Admin\" parmi le nombre de licence total", editable: true, type: "number", mandatoryAtCreation: true, defaultAtCreation: '0'},
	'licence.nbguichetstotal': {label: "Licence - Nombre de guichets total", editable: true, type: "number", mandatoryAtCreation: true, defaultAtCreation: '1'},
	'licence.type': {label: "License - Type de licence", editable: true, type: "enum", values: ['CURRENCY_AND_METAL', 'CURRENCY', 'METAL', 'PILOT'], mandatoryAtCreation: true, defaultAtCreation: 'CURRENCY'},
	'options.alertesms.actif': {label: "Options - Alertes SMS activées", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.analyserisque.actif': {label: "Options - Module d'analyse des risques activé", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.backup.actif': {label: "Options - Sauvegardes externalisées activées", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.backup.heuredebut': {label: "Options - Heure de démarrage des sauvegardes externalisées", editable: true, type: "time", mandatoryAtCreation: true, defaultAtCreation: '00:00:00'},
	'options.backup.s3.accesskey': {label: "Options - Clé d'accès AWS S3 pour stocker les sauvegardes", editable: true, type: "string", mandatoryAtCreation: false},
	'options.backup.s3.secretkey': {label: "Options - Clé secrète AWS S3 pour stocker les sauvegardes", editable: true, type: "string", mandatoryAtCreation: false},
	'options.detaxe.actif': {label: "Options - Module détaxe activé", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.dowjones.actif': {label: "Options - Module de screening Dow Jones activé", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.dowjones.dateabonnement': {label: "Options - Date de l'abonnement du module Dow Jones", editable: true, type: "string"},
	'options.dowjones.login': {label: "Options - Dow Jones - Login", editable: true, type: "string"},
	'options.dowjones.password': {label: "Options - Dow Jones - Mot de passe", editable: true, type: "string"},
	'options.gestionpersonnel.actif': {label: "Options - Module Gestion du personnel activé", editable: true, type: "boolean"},
	'options.identt.actif': {label: "Options - Module IdenTT activé", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.identt.nbguichets': {label: "Options - Module IdenTT - Nombre de guichets", editable: true, type: "number", mandatoryAtCreation: true, defaultAtCreation: '0'},
	'options.identt.login': {label: "Options - Module IdenTT - Login", editable: true, type: "string"},
	'options.identt.password': {label: "Options - Module IdenTT - Mot de passe", editable: true, type: "string"},
	'options.web.publication.actif': {label: "Options - Site Web - Publication activée", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.web.vente.actif': {label: "Options - Site Web - Vente activée", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.web.api.token': {label: "Options - Site Web - Jeton d'accès à l'API de synchro site web", editable: true, type: "string"},
	'options.web.api.url': {label: "Options - Site Web - URL de l'API de synchro site web", editable: true, type: "string", defaultAtCreation: "https://web-connect.api.yodaforex.fr"},
	'options.web.db.login': {label: "Options - Site Web - Login de connexion à la BDD - Obsolète depuis la 22.7", editable: true, type: "string"},
	'options.web.db.password': {label: "Options - Site Web - Mot de passe de connexion à la BDD - Obsolète depuis la 22.7", editable: true, type: "string"},
	'options.web.db.schema': {label: "Options - Site Web - Nom de la base - Obsolète depuis la 22.7", editable: true, type: "string"},
	'options.web.db.url': {label: "Options - Site Web - URL de la BDD - Obsolète depuis la 22.7", editable: true, type: "string"},
	'options.webinput.token.principal': {label: "Options - File d'Attente Virtuelle - Jeton d'accès maitre", editable: true, type: "string"},
	'options.webinput.token.qrcode': {label: "Options - File d'Attente Virtuelle - Jeton d'accès pour génération de QR Code", editable: true, type: "string"},
	'options.yodadisplay.actif': {label: "Options - Module YodaDisplay Filaire activé", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.yodaid.actif': {label: "Options - Module YodaID Assistant activé", editable: true, type: "boolean", mandatoryAtCreation: true, defaultAtCreation: 'false'},
	'options.yodaid.nbguichets': {label: "Options - YodaID Assistant - Nombre de guichets", editable: true, type: "number", mandatoryAtCreation: true, defaultAtCreation: '0'},
	'options.vignetteautoroutesuisse.actif': {label: "Options - Gestion de la vente de vignettes autoroutières suisses", editable: true, type: "boolean"},
	'options.mermaid.actif': {label: "Options - Verification de numéro de SIRET", editable: true, type: "boolean", defaultAtCreation: 'false'},
	'support.branchip': {label: "Support - IP de l'agence", editable: false, type: "string"},
	'support.zendesk.orgid': {label: "Support - Identifiant ZenDesk", editable: true, type: "string"},
	'support.axonaut.orgid': {label: "Support - Identifiant Axonaut", editable: true, type: "string"},
	'support.log.debug.actif': {label: "Support - Activation des logs en DEBUG", editable: true, type: "boolean"}
};

const LicenceBusiness = {
	_construct() {
		for (const key in this._keysDescriptions) {
			this._keysForSelectWithDescription.push({text: this._keysDescriptions[key].label, value: key});
			this._keysForSelectWithKeys.push({text: key, value: key});
			this._keysOnly.push(key);
		}
	},
	_keysDescriptions: keysDescription,
	_keysForSelectWithDescription: [],
	_keysForSelectWithKeys: [],
	_keysOnly: [],
	getKeys() {
		return this._keysOnly;
	},
	getKeysForSelectWithDescription() {
		return this._keysForSelectWithDescription;
	},
	getKeysForSelect() {
		return this._keysForSelectWithKeys;
	},
	getKeysMap() {
		return this._keysDescriptions;
	},
	createEmptyConfiguration() {
		let conf = {};
		for (let i = 0; i < this._keysOnly.length; i++) {
			let key = this._keysOnly[i];
			let keysDescription = this._keysDescriptions[key];
			if (keysDescription.editable) {
				if (typeof keysDescription.defaultAtCreation === 'string') {
					conf[key] = keysDescription.defaultAtCreation;
				} else {
					conf[key] = null;
				}
			}
		}
		return conf;
	},
	filters: {
		iconForStatus(licenseActivationStatus) {
			switch (licenseActivationStatus) {
				case "ACTIVE":
				case "ALLOWED":
					return "check_circle";
				case "FROZEN":
					return "mdi-snowflake";
				case "SUSPENDED":
					return "pan_tool";
				case "TERMINATED":
				case "NONE":
					return "cancel";
				default:
					return "help";
			}
		},
		colorForStatus(licenseActivationStatus) {
			switch (licenseActivationStatus) {
				case "ACTIVE":
				case "ALLOWED":
					return "success";
				case "FROZEN":
					return "blue lighten-2";
				case "SUSPENDED":
					return "warning";
				case "TERMINATED":
				case "NONE":
					return "error";
				default:
					return "primary";
			}
		},
		labelForConfKey(key) {
			let conf = keysDescription[key];
			return conf ? conf.label : conf;
		}
	}
}

LicenceBusiness._construct();

export {LicenceBusiness as LicenseBusiness};

<script>
import ComputerParkBusiness from "@/business/computer-park-business";

export default {
  name: "ComputerParkButton",
  props: {
    companyUuid: {
      type: String,
      required: false,
    },
    branchUuid: {
      type: String,
      required: false,
    },
    hideWhenNoStatus: {
      default: false,
      type: Boolean,
      required: false,
    }
  },
  data: () => ({
    status: [],
  }),
  mounted() {
    if (this.branchUuid) {
      return ComputerParkBusiness.getStatusForBranch(this.branchUuid).then(r => this.status = r);
    } else if (this.companyUuid) {
      return ComputerParkBusiness.getStatusForCompany(this.companyUuid).then(r => this.status = r);
    }

  },
  computed: {
    color() {
      if (this.status.indexOf("OBSOLETE") >= 0) return 'red darken-1';
      if (this.status.indexOf("STORAGE_ALERT") >= 0) return 'red';
      if (this.status.indexOf("STORAGE_WARNING") >= 0) return 'orange';
      if (this.status.indexOf("OS_NEEDS_UPDATE") >= 0) return 'purple accent-3';
      else return '';
    },
    statusText() {
      if (this.status && this.status.length) {
        let anomalies = [];
        if (this.status.indexOf("OBSOLETE") >= 0) anomalies.push("Machine(s) obsolète(s)");
        if (this.status.indexOf("STORAGE_ALERT") >= 0) anomalies.push("Stockage critique");
        if (this.status.indexOf("STORAGE_WARNING") >= 0) anomalies.push("Stockage faible");
        if (this.status.indexOf("OS_NEEDS_UPDATE") >= 0) anomalies.push("MàJ OS nécessaire");
        return anomalies.join(', ');
      }
      return 'Aucune anomalie à signaler';
    }
  }
}
</script>

<template>
  <span>
    <v-tooltip top v-if="!hideWhenNoStatus || (this.status && this.status.length)">Afficher le parc informatique de cette {{ this.companyUuid ? 'société' : 'agence' }}<br/><span class="caption">{{statusText}}</span>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-on="on" v-bind="attrs" x-small @click="$emit('click', $event)">
          <v-icon x-small :color="color">mdi-desktop-classic</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
  </span>
</template>

<style scoped>

</style>
